var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "nav-container" }, [
    _c("div", { staticClass: "nav" }, [
      _c("div", { staticClass: "logo", on: { click: _vm.goHome } }, [
        _c("img", { attrs: { src: require("../../assets/images/logo.png") } })
      ]),
      _c("section", { staticClass: "search" }, [
        _c(
          "div",
          { staticClass: "search-area" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          maxlength: "30",
                          placeholder:
                            "建议搜索关键词，如“职位发布”、“保证期”等"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchGet.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.keyword,
                          callback: function($$v) {
                            _vm.keyword = $$v
                          },
                          expression: "keyword"
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: { slot: "append", icon: "el-icon-search" },
                          on: { click: _vm.searchGet },
                          slot: "append"
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "menu" },
        [
          _c(
            "el-button",
            {
              staticClass: "ap",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.toCompany()
                }
              }
            },
            [
              _vm._v(" 企业版 "),
              _c("i", { staticClass: "el-icon-arrow-right" })
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "lt",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.toHunter()
                }
              }
            },
            [
              _vm._v(" 猎头版 "),
              _c("i", { staticClass: "el-icon-arrow-right" })
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }