<template>
  <header class="nav-container">
    <div class="nav">
      <div class="logo" @click="goHome">
        <img :src="require(`../../assets/images/logo.png`)" />
      </div>
      <section class="search">
        <div class="search-area">
          <el-form ref="form" @submit.native.prevent>
            <el-form-item>
              <el-input
                maxlength="30"
                placeholder="建议搜索关键词，如“职位发布”、“保证期”等"
                v-model="keyword"
                @keyup.enter.native="searchGet"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="searchGet"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </section>
      <div class="menu">
        <el-button type="primary" class="ap" @click="toCompany()">
          企业版 <i class="el-icon-arrow-right"></i
        ></el-button>
        <el-button type="primary" class="lt" @click="toHunter()">
          猎头版 <i class="el-icon-arrow-right"></i
        ></el-button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      keyword: "",
    };
  },
  created() {
    this.keyword = this.$route.query.keyword || "";
  },
  mounted() {},
  methods: {
    //搜索
    searchGet() {
      let router_path = this.$route.path;
      if (this.keyword === "") {
            this.$router.push({path: "/home"});
        return;
      }
      if (router_path !== "/search") {
        this.$router.push({
          path: "/search",
          query: { keyword: this.keyword },
        });
      } else {
        this.$router.push({
          path: "/search",
          query: {keyword: this.keyword},
        });
        this.$emit("searchFunc", this.keyword);
      }
    },
    //回到首页
    goHome() {
      this.$router.push({ path: "/home" }, { query: {} });
        this.$router.go(0)
    },
    //跳转企业
    toCompany() {
      window.open(process.env.VUE_APP_COMPANY_URL);
    },
    //跳转猎头
    toHunter() {
      window.open(process.env.VUE_APP_HUNTER_URL);
    },
  },
};
</script>

<style scoped lang="scss">
.nav-container {
  width: 100%;
  height: 70px;
  overflow: hidden;
  background: #0084ff;
  .nav {
    max-width: 1180px;
    height: 100%;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1003;
    .logo {
      width: 150px;
      margin-right: 50px;
      img {
        width: 163px;
        height: 24px;
        cursor: pointer;
      }
    }
    .menu {
      .el-button {
        width: 108px;
        height: 38px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 19px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
::v-deep .search {
  flex: 1;
  height: 38px;
  background: #f8f8f8;
  border-radius: 19px;
  margin-right: 40px;
  .el-input-group__append {
    background: transparent;
    border: none;
  }
  .search-area {
    display: flex;
    align-items: center;
  }
  .el-form {
    flex: 1;
    margin-right: 10px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .el-input__inner {
    background: transparent;
    border: none;
  }
}
</style>
